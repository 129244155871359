<template>
    <div>
      <c-search-box  @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant type="none" name="plantCd" v-model="searchParam.plantCd"  @datachange="getMeasure"/>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              label="측정년도"
              name="measureYear"
              type="year"
              default="today"
              v-model="searchParam.measureYear"
              @datachange="getMeasure"
            />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              type="none"
              codeGroupCd="AIR_INSPECT_TYPE_CD"
              itemText="codeName"
              itemValue="code"
              label="측정구분"
              name="airInspectTypeCd"
              v-model="searchParam.airInspectTypeCd"
              @datachange="getMeasure">
            </c-select>
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              :comboItems="measureItems"
              required
              type="edit"
              itemText="envAirSelfMeasureMstName"
              itemValue="envAirSelfMeasureMstId"
              name="envAirSelfMeasureMstId"
              label="측정 회차"
              @datachange="getList"
              v-model="searchParam.envAirSelfMeasureMstId">
            </c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              :comboItems="inspectItems"
              type="search"
              itemText="envAirMstInspectItemName"
              itemValue="envAirMstInspectItemId"
              name="envAirMstInspectItemId"
              label="검사항목"
              @datachange="getList"
              v-model="searchParam.envAirMstInspectItemId">
            </c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-checkbox
              :isFlag="true"
              label="그래프 라벨표시"
              name="checkPoint"
              v-model="checkPoint"
              @datachange="checkPointDatachange">
            </c-checkbox>
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <div class="float-right gridbtntop" style="margin-left:auto;">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
        <div class="col-12" style="background:#fff;">
          <apexchart 
            ref="chart1" 
            type="line"
            :height="chart.height" 
            :options="chart.chartOptions" 
            :series="chart.series"></apexchart>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'self-air-measure-graph',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          mainPeriod: [],
          mainPlantCd: null,
        }
      },
    },
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      checkPoint: 'N',
      fullscreen: false,
      searchParam: {
        plantCd: '',
        measureYear: '',
        airInspectTypeCd: null,
        envAirSelfMeasureMstId: null,
        envAirMstInspectItemId: null,
      },
      year: [],
      chart: {
        height: '600',
        chartOptions: {
          title: {
            text: '측정회차별 추이분석'
          },
          annotations: {
            yaxis: [],
          },
         // 그래프 설정
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '항목수치'
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
        },
        series: [
          {
            name: "항목수치",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      isYear: false,
      inspectItems: [],
      measureItems: [],
      itemListUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // 메인에서 호출한 팝업파라미터
      if (this.popupParam.mainPlantCd) {
        this.searchParam.plantCd = (!this.popupParam.mainPlantCd || this.popupParam.mainPlantCd.length > 3 ? null : this.popupParam.mainPlantCd);
      }
      this.listUrl = selectConfig.env.air.self.measure.graph.url + '2';
      this.itemListUrl = selectConfig.env.air.mst.inspect.plant.url;

      this.setSize();
      this.getMeasure();
    },
    checkPointDatachange() {
      if (this.checkPoint == 'Y') {
        this.$set(this.chart.chartOptions.dataLabels, 'enabled', true)
      } else {
        this.$set(this.chart.chartOptions.dataLabels, 'enabled', false)
      }
      this.$refs['chart1'].refresh();
    },
    getItems() {
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        airInspectTypeCd: this.searchParam.airInspectTypeCd,
      };
      this.$http.request((_result) => {
        this.inspectItems = _result.data;
      },);
    },
    getMeasure() {
      this.$http.url = selectConfig.env.air.self.measure.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        measureYear: this.searchParam.measureYear,
        plantCd: this.searchParam.plantCd,
        airInspectTypeCd: this.searchParam.airInspectTypeCd,
      };
      this.$http.request((_result) => {
        this.measureItems = _result.data;
        this.searchParam.envAirSelfMeasureMstId = null;
        this.searchParam.envAirMstInspectItemId = null;
        this.getItems();
      },);
    },
    getList() {
      this.chart.chartOptions.annotations.yaxis = [];
      delete this.chart.chartOptions.yaxis.max;
      
      if (this.searchParam.envAirSelfMeasureMstId !== null) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        let _mstsId = this.searchParam.envAirMstInspectItemId;
        this.$http.request((_result) => {
          this.chart.series = [];
          let xaxis = [];     
          this.$_.forEach(_result.data, _item1 => {     
            let idx = xaxis.indexOf(_item1.envAirMstOutletName) 
            if (idx === -1) {
              xaxis.push(_item1.envAirMstOutletName);
            }
          });
          if (!_mstsId) {
            this.$_.forEach(this.inspectItems, _item2 => {
              let _data = [];
              this.$_.forEach(_result.data, _item3 => {
                if (_item3.envAirMstInspectItemId == _item2.envAirMstInspectItemId) {
                  _data.push(_item3.measure);
                }
              });
              this.chart.series.push({
                envAirMstInspectItemId: _item2.envAirMstInspectItemId,
                name: _item2.envAirMstInspectItemName,
                data: _data,
              });
            })
          } else {
            let _data = [];
            this.$_.forEach(_result.data, _item3 => {
              if (_item3.envAirMstInspectItemId == _mstsId) {
                _data.push(_item3.measure);
              }
            });
            this.chart.series.push({
              envAirMstInspectItemId: _mstsId,
              name: '측정값',
              data: _data,
            });
          }

          this.$set(this.chart.chartOptions.title, 'text', '측정회차별 추이분석')
          this.chart.chartOptions.xaxis.categories = xaxis;

          if (this.chart.series.length > 0) {
            if (_mstsId !== null) {
              let anos  = this.$_.filter(this.inspectItems, {envAirMstInspectItemId : _mstsId });
              let _datas = [];
              this.$_.forEach(this.chart.series, _items => {
                this.$_.forEach(_items.data, _item => {
                  _datas.push(parseFloat(_item))
                })
              }) 
              let _maxVal = Math.max.apply(null, _datas);
              let _legalStandard = anos[0].legalStandard;
              let _innerStandard = anos[0].innerStandard;
              if (_legalStandard > _innerStandard) {
                if (_maxVal > _legalStandard) {
                  this.chart.chartOptions.yaxis.max = _maxVal;
                } else {
                  this.chart.chartOptions.yaxis.max = _legalStandard;
                }
              } else {
                if (_maxVal > _innerStandard) {
                  this.chart.chartOptions.yaxis.max = _maxVal;
                } else {
                  this.chart.chartOptions.yaxis.max = _innerStandard;
                }
              }
              // if (anos.length > 0) {
              //   this.chart.chartOptions.annotations.yaxis = [
              //     {
              //       y: _legalStandard,
              //       borderColor: '#e53935',
              //       label: {
              //         borderColor: '#e53935',
              //         style: {
              //           color: '#fff',
              //           background: '#e53935',
              //         },
              //         text: '법적기준 : ' + String(_legalStandard) + '',
              //       }
              //     },
              //     {
              //       y: _innerStandard,
              //       borderColor: '#ffd600',
              //       label: {
              //         borderColor: '#ffd600',
              //         style: {
              //           color: '#000',
              //           background: '#ffd600',
              //         },
              //         text: '내부기준 : ' + String(_innerStandard) + '',
              //       }
              //     },
              //   ];
              // }
            } else {
              this.chart.chartOptions.annotations.yaxis = [];
              delete this.chart.chartOptions.yaxis.max;
            }
          } else {
            this.chart.chartOptions.annotations.yaxis = [];
            delete this.chart.chartOptions.yaxis.max;
          }

          this.$refs['chart1'].refresh();
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message:
          '측정 회차를 선택하세요',
          type: 'warning', // success / info / warning / error
        });
      }
    },
    setSize() {
      let tempHeight = (window.innerHeight - (300));
      if (tempHeight < 300) {
        tempHeight = 300;
      }
      this.$set(this.chart, 'height', tempHeight)
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>